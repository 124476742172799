*body {
  font-family: 'Roboto', sans-serif;
  color: #fff;
  height: 100vh;
  margin-bottom: 200px;

}

.ui {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}


.projects-container {
  padding-top: 150px;
  padding-bottom: 200px;
}





@import url('https://fonts.googleapis.com/css2?family=Handjet:wght@500&family=IBM+Plex+Mono:ital,wght@0,700;1,500&family=Oswald:wght@200;300&family=Petit+Formal+Script&family=Sacramento&family=Style+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300&family=Style+Script&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300&family=Petit+Formal+Script&family=Sacramento&family=Style+Script&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Handjet:wght@500&family=Oswald:wght@200;300&family=Petit+Formal+Script&family=Sacramento&family=Style+Script&display=swap');


.myS {
  font-family: 'Sacramento', cursive;
  padding-top: 30px;
}


.navbar-container {
  height: 80px;
  display: flex;
  width: 100%;
  justify-content: center;
  position: fixed;
  top: 10px;
  background-color: #044d4e;
  border-radius: 48px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}



.sidebar {
  background-color: rgba(255, 255, 255, 0.4);
  padding: 10px;
  position: fixed;
  top: 86%;
  width: 100%;
  left: 0;
}


.image-and-text-container {
  display: flex;
  align-items: center;
}

.skill-image {
  margin-bottom: 30px;
}

.skill-description {
  color: #025959;
  font-size: 20px;
  font-weight: bold;
  padding-left: 20px;
}

.popup-container {
  position: absolute;
  top: 30%;
  left: 40%;
  background-color: white;
  border: 2px solid #A65151;
  padding: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  border-radius: 12px;
}


.popup-container img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  margin-bottom: 10px;
}

.skills-container {
  padding: 50px;


}


.card-body {
  background-color: #025959;
  padding: 20px;

}



.image-container {
  display: flex;
  align-items: center;
  justify-content: center;

}

.hiImage-container {
  display: flex;
  align-items: center;
  justify-content: center;

}

.Story {
  position: absolute;
  left: 5%;
}


.samImg {
  width: 300px;
  height: 300px;

}



.image-container {
  position: absolute;
  top: 60%;
  left: 26%;
  right: 0;
  height: 170px;

  padding-top: 30px;

}

.hiImage-container {
  position: absolute;
  top: 80%;
  left: 26%;
  right: 0;
  height: 170px;
  padding-top: 30px;
}

.about-container {
  position: absolute;
  left: 26%;
  text-align: left;
  color: #000;
  margin-right: 20px;


}

.button {
  background-color: #025959;
  border: 2px solid #fff;
  border-radius: 30px;
  color: #fff;
  display: inline-block;
  margin-top: 10px;
  padding: 10px 28px;
  font-size: 1.25rem;
  text-decoration: none;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s;
}

.button:hover {
  background-color: #fff;
  color: #025959;
  border-color: #fff;
  transform: scale(1.1);
}


.col-4-myS h2 {
  text-align: center;

}








@media (max-width: 768px) {
  .socials-sidebar {
    display: flex;
    justify-content: center;

  }

  .hw {
    position: relative !important;
    right: -80px !important;
    top: +74px !important;
    font-size: 38px !important;
  }

  .designer {
    font-size: 28px !important;
  }

  .ui {
    font-size: 60px !important;
  }

  .samImg {
    width: 220px !important;
    height: 220px !important;

  }

  .nav-link {
    font-size: 18px !important;
    margin-left: 8px !important;
    margin-right: 8px !important;

  }

  .navbar-container {
    height: 60px;
    ;
  }

  .full-width-image-container {
    height: 200px !important;
    position: relative;
    top: 30px;
  }

  .col-md-6.mb-4 h1 {
    font-size: 50px !important;

  }

  .col-md-6.mb-4 h3 {
    font-size: 20px !important;
    text-align: center !important;


  }

  .col-md-6.mb-4 {
    text-align: center !important;
  }

  .d-grid.gap-2 {
    margin-bottom: 130px !important;
  }

  .col-sm-12.text-center p {
    font-size: 18px !important;
    font-weight: normal !important;
    padding: 10px !important;
  }


}